import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { translation } from '@assist/shared/translations';
import { TranslocoModule } from '@ngneat/transloco';
import { IconComponent, cueCheckCircle } from '@cue/ui/icons';

@Component({
  selector: 'assist-reserved-notification',
  imports: [RouterLink, TranslocoModule, IconComponent],
  templateUrl: './reserved-notification.component.html',
  styleUrl: './reserved-notification.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReservedNotificationComponent {
  id = input<string>('');
  subject = input<string>('');
  email = input<string>('');
  protected readonly translation = translation;

  ICONS = {
    cueCheckCircle,
  };
}
