import { Injectable } from '@angular/core';
import { injectLazy } from 'ngxtension/inject-lazy';

import type { NotificationService as KendoNotificationService } from '@progress/kendo-angular-notification';
import { ReservedNotificationComponent, SuccessNotificationComponent } from '@assist/notifications/ui';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  #kendoNotificationService = injectLazy(() => import('@progress/kendo-angular-notification').then((m) => m.NotificationService) as any);

  reserved(id: string, subject: string, email: string): void {
    this.#kendoNotificationService.subscribe((s) => {
      const service = s as KendoNotificationService;
      const ref = service.show({
        content: ReservedNotificationComponent,
        hideAfter: 4000,
        position: { horizontal: 'right', vertical: 'bottom' },
        animation: { type: 'fade', duration: 400 },
      });

      ref.content.setInput('id', id);
      ref.content.setInput('subject', subject);
      ref.content.setInput('email', email);
    });
  }

  success(title: string, message: string): void {
    this.#kendoNotificationService.subscribe((s) => {
      const service = s as KendoNotificationService;
      const ref = service.show({
        content: SuccessNotificationComponent,
        hideAfter: 4000,
        position: { horizontal: 'right', vertical: 'bottom' },
        animation: { type: 'fade', duration: 400 },
      });

      ref.content.setInput('title', title);
      ref.content.setInput('text', message);
    });
  }
}
