import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { IconComponent, cueCheckCircle } from '@cue/ui/icons';
import { translation } from '@assist/shared/translations';

@Component({
  selector: 'assist-success-notification',
  imports: [IconComponent],
  templateUrl: './success-notification.component.html',
  styleUrl: './success-notification.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuccessNotificationComponent {
  title = input<string>('');
  text = input<string>('');

  ICONS = {
    cueCheckCircle,
  };
  protected readonly translation = translation;
}
