import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map } from 'rxjs';
import * as EventsActions from '@assist/shared/data';
import { tap } from 'rxjs/operators';
import { NotificationService } from './notification.service';
import { Router } from '@angular/router';
@Injectable()
export class NotificationsEffects {
  loadEventsWhenUserHasChanged$$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EventsActions.eventCreated),
        filter((x) => x.roomEmail != null),
        tap((action) => {
          this.notificationService.reserved(action.id, action.subject, action.roomEmail);
          if (this.router.routerState.snapshot.url.includes('reservations')) {
            this.router.navigate(['/reservations', 'reserve']);
          }
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private notificationService: NotificationService,
  ) {}
}
